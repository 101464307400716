import React, {Component} from 'react';
import PageWrapper from './components/PageWrapper';
import Home from './components/Pages/Home';


class App extends Component {
  render () {
    //console.log('public url: ', process.env.PUBLIC_URL);
    return (
      <PageWrapper>
        <Home />
      </PageWrapper>
    );
  }
}

export default App;

/* original code

import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/